import React from 'react'
import { graphql } from 'gatsby'
import { sortBy, first } from 'lodash'

import Grid from '../components/project/grid'
import Tall from '../components/project/row'
import Layout from '../components/layout'

export default class IndexPage extends React.PureComponent {
  getGallery(galleries, type) {
    if (type === 'tall') {
      return <Tall galleries={galleries} field="tall" />
    }
    return <Grid galleries={galleries} />
  }

  render() {
    const node = first(this.props.data.project.edges).node
    const galleries = sortBy(node.galleries, 'name').reverse()

    return (
      <Layout
        image={first(galleries).poster}
        currentProject={node}
        title={node.title}
      >
        {this.getGallery(galleries, node.layout)}
      </Layout>
    )
  }
}

export const query = graphql`
  query ProjectPage($slug: String!) {
    project: allProject(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          title
          name
          slug
          layout
          galleries {
            title
            formattedDate
            slug
            poster {
              src
              width
              height
              colors {
                dark
                light
              }
              grid: fixed(
                width: 640
                height: 424
                quality: 60
                gravity: "auto"
                crop: "fill"
              ) {
                src
                width
                height
                aspectRatio
                srcSet
              }
              tall: fixed(
                width: 900
                height: 300
                quality: 90
                gravity: "auto"
                crop: "fill"
              ) {
                src
                width
                height
                aspectRatio
                srcSet
              }
            }
          }
        }
      }
    }
  }
`
