import React from 'react'
import { Link } from 'gatsby'
import styles from './grid.module.scss'
import Img from 'gatsby-image'

export default class GalleryGrid extends React.PureComponent {
  render() {
    return (
      <div className={styles.thumbs}>
        {this.props.galleries.map((gallery, index) => {
          const poster = gallery.poster
          return (
            <Link
              to={gallery.slug}
              key={gallery.slug}
              className={styles.thumb}
              style={{
                color: poster.colors.dark,
              }}
            >
              <Img
                fixed={poster.grid}
                alt={gallery.title}
                placeholderStyle={{
                  opacity: 1,
                }}
                fadeIn={false}
                style={{
                  width: '100%',
                  height: 'auto',
                  paddingBottom: `${100 / poster.grid.aspectRatio}%`,
                  backgroundColor: poster.colors.light,
                }}
              />
              <label>
                <div>
                  <div className={styles.title}>{gallery.title}</div>
                  {gallery.formattedDate ? (
                    <small>{gallery.formattedDate}</small>
                  ) : null}
                </div>
              </label>
            </Link>
          )
        })}
      </div>
    )
  }
}
