import React from 'react'
import styles from './row.module.scss'
import RowItem from './row-item'

export default class GalleryTallRow extends React.PureComponent {
  render() {
    return (
      <div className={styles.thumbs}>
        {this.props.galleries.map(gallery => {
          return (
            <RowItem
              key={gallery.title}
              gallery={gallery}
              field={this.props.field}
            />
          )
        })}
      </div>
    )
  }
}
