import React from 'react'
import { Link } from 'gatsby'
import RedRoom from '../redroom'
import styles from './row-item.module.scss'
import Img from 'gatsby-image'

export default class GalleryTallRow extends React.Component {
  state = {
    isAnimating: false,
  }
  render() {
    const gallery = this.props.gallery
    const poster = gallery.poster
    return (
      <Link
        to={gallery.slug}
        key={gallery.slug}
        className={styles.thumb}
        style={{
          color: 'black',
        }}
        onMouseEnter={() => this.setState({ isAnimating: true })}
        onMouseLeave={() => this.setState({ isAnimating: false })}
      >
        <Img
          fixed={poster[this.props.field]}
          alt={gallery.title}
          placeholderStyle={{
            opacity: 1,
          }}
          fadeIn={false}
          style={{
            width: '100%',
            height: 'auto',
            paddingBottom: `${100 / poster[this.props.field].aspectRatio}%`,
            backgroundColor: poster.colors.light,
          }}
        />
        <label>
          <div>
            <RedRoom
              isAnimating={this.state.isAnimating}
              text={gallery.title}
            />
            {gallery.formattedDate ? (
              <small>{gallery.formattedDate}</small>
            ) : null}
          </div>
        </label>
      </Link>
    )
  }
}
