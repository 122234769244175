import React from 'react'
import styles from './redroom.module.scss'
import { compact } from 'lodash'

export default class RedRoom extends React.PureComponent {
  render() {
    const { isAnimating } = this.props
    return (
      <div
        className={compact([
          styles.redroom,
          isAnimating ? styles.animating : null,
        ]).join(' ')}
        onMouseEnter={() => this.setState({ isAnimating: true })}
        onMouseLeave={() => this.setState({ isAnimating: false })}
      >
        {this.props.text.split('').map((letter, index) => (
          <span
            key={index}
            style={isAnimating ? { transitionDelay: `${30 * index}ms` } : null}
          >
            {letter}
          </span>
        ))}
      </div>
    )
  }
}
